import { isMobile } from "../utils/index";
export default {
    address: "新莊區中環路一段6號",
    googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.8102271099735!2d121.44219682924644!3d25.025897214195936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x20363b4000ab1fc6!2zMjXCsDAxJzMzLjIiTiAxMjHCsDI2JzMzLjkiRQ!5e0!3m2!1szh-TW!2stw!4v1650958713012!5m2!1szh-TW!2stw",
    googleLink: "https://goo.gl/maps/kTWEMcijPyWJFpLR9",
    phone: "02-22045228",
    fbLink: "https://facebook.com/111571768201050",
    fbMessage: "https://m.me/111571768201050/",
    caseName: "若水岸",
    indigatorLength: 10,
    houseInfos: [
        ["投資興建", "璟華建設股份有限公司"],
        ["坪數規劃", "28坪 、 36坪"],
        ["建築設計", "莊志寬建築師事務所"],
        ["房型規劃", "2房 、 3房"],
        ["行銷團隊", "鴻智廣告有限公司"],
        ["公設比", "31～32%"],
        ["使照號碼", "110莊使字第00363號"],
        ["建案位置", "新莊區中環路一段6號"],
    ],

    gtmCode: ["52T8ZWZ"], // 可放置多個
    recaptcha_site_key_v2: "6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC",
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "聯絡我們",
        subTitle: isMobile ?
            "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！" :
            "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！ ",
    },
};